import { Component, Input } from '@angular/core';
import { Order } from 'src/app/models';

@Component({
  selector: 'app-order-pickup-point',
  templateUrl: './order-pickup-point.component.html',
  styleUrls: ['./order-pickup-point.component.scss'],
})
export class OrderPickupPointComponent {
  @Input() order: Order;
  data: Array<string> = ['shipping-point-label', 'collection-type', 'flight-number', 'flight-date'];
}
