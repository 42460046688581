import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import JsBarcode from 'jsbarcode';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  catchError,
  delay,
  forkJoin,
  iif,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { Channel } from 'src/app/constants/';
import { CIVILITY, PREPARED_ORDER_REFRESH_TIMER } from 'src/app/constants/constants';
import { isCancelled, OrderStatus, statusCollected } from 'src/app/constants/order-status';
import { OrderlineStatus } from 'src/app/constants/orderline-status';
import { PaymentMethod } from 'src/app/constants/payment-method';
import { Target } from 'src/app/constants/target';
import { ClientInfo } from 'src/app/models/client-info';
import { BoardingPass, Order } from 'src/app/models/order';
import { Orderline } from 'src/app/models/orderline';
import { ProductInfo } from 'src/app/models/product-info';
import { ProductStock } from 'src/app/models/product-stock';
import { ProductVariation } from 'src/app/models/product-variation';
import { ReplenishmentRequestStatus } from 'src/app/models/replenishment-request-status';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HistoryService } from 'src/app/services/history.service';
import { OrderService, OrderStatusEndpoint } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';
import { ReplenishmentService } from 'src/app/services/replenishment.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog/dialog.component';

import { CancelOrderDialogComponent } from './cancel-order-dialog/cancel-order-dialog.component';
import { CancelOrderlineDialogComponent } from './cancel-orderline-dialog/cancel-orderline-dialog.component';
import { CancelValidationOrderDialogComponent } from './cancel-validation-order-dialog/cancel-validation-order-dialog.component';
import { ConfirmDeliveryDialogComponent } from './confirm-delivery-dialog/confirm-delivery-dialog.component';
import { ConfirmForceCollectedDialogComponent } from './confirm-force-collected-dialog/confirm-force-collected-dialog.component';
import { ConfirmForceToCollectDialogComponent } from './confirm-force-to-collect-dialog/confirm-force-to-collect-dialog.component';
import { GeneralDeliveryDialogComponent } from './general-delivery-dialog/general-delivery-dialog.component';
import { PreparationNotFinishedDialogComponent } from './preparation-not-finished-dialog/preparation-not-finished-dialog.component';
import { PromotionDialogComponent } from './promotion-dialog/promotion-dialog.component';
import { ResetTcposDialogComponent } from './reset-tcpos-dialog/reset-tcpos-dialog.component';
import { SignatureOrderDialogComponent } from './signature-order-dialog/signature-order-dialog.component';
import { SkuDialogComponent } from './sku-dialog/sku-dialog.component';
import { SpecifyStorageDialogComponent } from './specify-storage-dialog/specify-storage-dialog.component';
import { OrderUnpaidDialogComponent } from './order-unpaid-dialog/order-unpaid-dialog';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnDestroy, AfterViewInit {
  _order: Order;
  loading = true;
  specifyStorageRef: DynamicDialogRef | undefined;
  signatureDialogRef: DynamicDialogRef | undefined;
  cancelValidationRef: DynamicDialogRef | undefined;
  cancelRef: DynamicDialogRef | undefined;
  skuRef: DynamicDialogRef | undefined;
  deliveryRef?: DynamicDialogRef;
  promotionRef: DynamicDialogRef | undefined;
  resetTcposRef: DynamicDialogRef | undefined;
  preparationNotFinishedRef: DynamicDialogRef;
  confirmDeliveryRef: DynamicDialogRef;
  confirmForceCollectedRef: DynamicDialogRef;
  confirmForceToCollectRef: DynamicDialogRef;
  orderUnpaidRef: DynamicDialogRef;
  dialogFlightDateRef?: DynamicDialogRef;
  logMessageForm: FormGroup = this.fb.group({
    logMessage: ['', [Validators.required]],
  });
  ctaAction = () => this.toastMessageService.warn('Action not defined');
  adminCta0Action = () => this.toastMessageService.warn('Action CTA 0 not defined');
  adminCta1Action = () => this.toastMessageService.warn('Action CTA 1 not defined');
  viewPick = false; // user can view the picking area (right side of the table)
  pickMode = false; // user can click to pick a productline
  civility = CIVILITY;
  orderStatus = OrderStatus;
  OrderlineStatus = OrderlineStatus;
  Target = Target;
  Channel = Channel;
  @ViewChild('barcode') barcode: ElementRef;
  @ViewChild('orderCancelButton') orderCancelButton: ElementRef;
  barcordeGenerated = false;
  viewInitObs = new ReplaySubject(1);
  isCancelled = isCancelled;
  promotionsDisplay: any = [];
  stopUpdateStatusWithIntervalSubject = new Subject<boolean>();
  isSuperAdmin = this.authenticationService.isSuperAdmin();
  isADR = false;
  stocksSelection: { [key: string]: Partial<ProductStock> } = {};
  quantitySelection: { [key: string]: number } = {};
  loadingTable = false;
  stocksWone: { [key: string]: number | null } = {};
  boardingPass: BoardingPass;
  viewSignature = false;
  replenishable = false;

  showDisplayPromotion(promotions: any[]) {
    if (this.promotionRef) {
      return; // prevent opening popin multiple times
    }
    this.promotionsDisplay = promotions ?? [];

    this.promotionRef = this.dialogService.open(PromotionDialogComponent, {
      showHeader: false,
      data: {
        promotions: promotions,
      },
    });
    this.promotionRef.onDestroy.subscribe(() => (this.promotionRef = undefined));
  }

  set order(order: Order) {
    this._order = order;
    this.stocksSelection = {};
    order.orderlinesJsonData.forEach((line) => {
      if (line.stocks?.length && line.stocks?.length > 1) {
        const id =
          line.pickedFrom ||
          (order.target === Target.VAP ? line.stocks[0].organizationId : undefined);
        this.stocksSelection[line.orderlineId] = {
          organizationId: id,
          organizationCode: this.getOrganizationCode(id, line.stocks),
        };
      } else if (line.stocks?.length === 1) {
        this.stocksSelection[line.orderlineId] = {
          organizationId: line.stocks[0].organizationId,
          organizationCode: this.getOrganizationCode(line.stocks[0].organizationId, line.stocks),
        };
      } else {
        this.stocksSelection[line.orderlineId] = {
          organizationId: undefined,
          organizationCode: '',
        };
      }
      this.quantitySelection[line.orderlineId] =
        line?.stocks[0]?.stock && this.canPreparePartially()
          ? Math.min(line.productOrderedQty, line.stocks[0].stock)
          : line.productOrderedQty;
    });
    this.isADR = this.order?.orderChannel == Channel.ADR;
    this.replenishable = this.authenticationService.isReplenishable(this.order?.shopId);
    this.viewSignature =
      statusCollected.some((status) => status === this.order?.orderStatus) && !this.isADR;
    this.updateCtaAction();
  }

  get order(): Order {
    return this._order;
  }

  constructor(
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
    private fb: FormBuilder,
    private toastMessageService: ToastMessagesService,
    private location: Location,
    private productService: ProductService,
    private replenishmentService: ReplenishmentService,
    private historyService: HistoryService,
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
  ) {
    const id = this.activatedRoute.snapshot.params.id;
    this.pickMode = !!this.activatedRoute.snapshot.data.pickMode;
    this.refreshOrder(id).subscribe(() => {
      if (this.activatedRoute.snapshot.queryParams?.action === 'cancel') {
        this.displayCancelDialog();
      } else if (this.activatedRoute.snapshot.queryParams?.action === 'prepare') {
        this.prepare();
      }
      if ((this.location.getState() as any).delivery) {
        this.openDeliveryDialog();
      }
    });
  }

  getRRStatus(orderline: Orderline): ReplenishmentRequestStatus {
    return this.replenishmentService.getReplenishmentRequestStatus(orderline.replenishmentRequest);
  }

  ngAfterViewInit(): void {
    this.viewInitObs.next(true);
  }

  ngOnDestroy() {
    if (this.specifyStorageRef) {
      this.specifyStorageRef.close();
    }
    if (this.cancelRef) {
      this.cancelRef.close();
    }

    if (this.cancelValidationRef) {
      this.cancelValidationRef.close();
    }
    if (this.skuRef) {
      this.skuRef.close();
    }

    this.deliveryRef?.close();
    this.dialogFlightDateRef?.close();

    if (this.preparationNotFinishedRef) {
      this.preparationNotFinishedRef.close();
    }

    if (this.confirmDeliveryRef) {
      this.confirmDeliveryRef.close();
    }

    if (this.signatureDialogRef) {
      this.signatureDialogRef.close();
    }

    if (this.resetTcposRef) {
      this.confirmDeliveryRef.close();
    }
  }

  refreshOrder(id = this.order?.orderId, orderLineId = ''): Observable<Order> {
    this.deliveryRef?.close();
    return this.orderService.getById(id).pipe(
      switchMap((orderResponse) =>
        iif(
          () => !Boolean(orderLineId),
          this.updateStocks(orderResponse),
          this.updateStocksForOrderLine(orderResponse),
        ),
      ),
      tap((order) => {
        this.order = order;
        this.loading = false;
        this.getBoardingPassInformation();
        this.onStatusUpdate();
        this.generateBarcode();
        this.order = this.replenishmentService.updateOrder(this.order, order.requests);
      }),
    );
  }

  getBoardingPassInformation() {
    if (this.order.confirmedBoardingPass) {
      this.orderService
        .getBoardingPassInformation(this.order.confirmedBoardingPass)
        .subscribe((boardingPass) => {
          this.boardingPass = boardingPass;
        });
    }
  }

  getMerchantStocksByOrderline(orderlineId: string, merchantId: string): ProductStock[] | null {
    let stocks: ProductStock[] | null = null;
    this.order.orderlinesJsonData.forEach((orderline) => {
      if (orderline.orderlineId === orderlineId) {
        for (const stock of orderline.stocks) {
          if (stock.organizationId === merchantId) {
            stocks = orderline.stocks;
            break;
          }
        }
      }
    });
    return stocks;
  }

  refresh() {
    this.loading = true;
    this.barcordeGenerated = false;
    this.refreshOrder().subscribe();
  }

  signatureDialog(): void {
    if (this.signatureDialogRef) {
      return; // prevent opening the popin multiple times
    }
    this.signatureDialogRef = this.dialogService.open(SignatureOrderDialogComponent, {
      showHeader: false,
      data: {
        orderId: this.order.orderId,
      },
    });

    this.signatureDialogRef.onDestroy.subscribe(() => (this.signatureDialogRef = undefined));
  }

  specifyStorage(): void {
    if (this.specifyStorageRef) {
      return; // prevent opening popin multiple times
    }

    this.specifyStorageRef = this.dialogService.open(SpecifyStorageDialogComponent, {
      showHeader: false,
      data: {
        storage: this.order.orderStorageLocation,
      },
    });
    this.specifyStorageRef.onClose.subscribe((orderStorageLocation: string) => {
      if (orderStorageLocation !== null) {
        this.orderService
          .update({
            orderId: this.order.orderId,
            orderStorageLocation,
          })
          .pipe(switchMap((order) => this.updateStocks(order, true)))
          .subscribe((order) => {
            this.order = order;
          });
      }
    });
    this.specifyStorageRef.onDestroy.subscribe(() => (this.specifyStorageRef = undefined));
  }

  displayCancelDialog(force = false): void {
    if (this.cancelRef) {
      return; // prevent opening the popin multiple times
    }
    if (this.orderCancelButton) {
      this.orderCancelButton.nativeElement.disabled = true;
    }

    this.cancelRef = this.dialogService.open(CancelOrderDialogComponent, {
      showHeader: false,
      data: {
        order: this.order,
        force: force,
      },
    });

    this.cancelRef.onClose.subscribe((action: string) => {
      this.removeActionFromUrl();

      if (this.orderCancelButton) {
        this.orderCancelButton.nativeElement.disabled = false;
      }

      if (action === 'cancel') {
        this.updateStatus('cancel');
      }

      if (action === 'force-cancel') {
        this.updateStatus('force-cancel');
      }
    });

    this.cancelRef.onDestroy.subscribe(() => (this.cancelRef = undefined));
  }

  displayResetTcposDialog(): void {
    if (this.resetTcposRef) {
      return; // prevent opening the popin multiple times
    }

    this.resetTcposRef = this.dialogService.open(ResetTcposDialogComponent, {
      showHeader: false,
      data: {
        order: this.order,
      },
    });

    this.resetTcposRef.onClose.subscribe((confirm: boolean) => {
      if (confirm) {
        this.updateStatus('reset-tcpos-order');
      }
    });
    this.resetTcposRef.onDestroy.subscribe(() => (this.resetTcposRef = undefined));
    // replace URL without reloading component
    this.location.replaceState(`/order/${this.order.orderId}`);
  }

  displaySkuPopin(orderline: Orderline): void {
    if (this.skuRef) {
      return; // prevent opening the popin multiple times
    }
    this.skuRef = this.dialogService.open(SkuDialogComponent, {
      showHeader: false,
      data: {
        orderline,
      },
    });
    this.skuRef.onDestroy.subscribe(() => (this.skuRef = undefined));
  }

  openDeliveryDialog = () => {
    if (
      this.deliveryRef ||
      this.order?.orderStatus !== OrderStatus.READY_FOR_COLLECT ||
      this.order?.orderChannel === Channel.ADR ||
      this.order?.orderPaymentMethod === PaymentMethod.CLICK_AND_RESERVE
    ) {
      return;
    }

    if (this.canDeliver() === false) {
      this.displayOrderUnpaidDialog();

      return;
    }

    // Ensure the token has not expired before to start the delivery process.
    this.orderService.getById(this.order.orderId);

    const clientInfo: Partial<ClientInfo> = {
      passenger: this.order?.orderCustomerJsonData?.first_name.concat(
        ' ',
        this.order?.orderCustomerJsonData?.last_name,
      ),
      flightDate: new Date(this.order?.orderFlightInfos[0]?.departureDateTime),
      destinationAirport: this.order?.orderFlightInfos[0]?.arrivalAirportIataCode,
      originAirport: this.order?.orderFlightInfos[0]?.departureAirportIataCode,
      vol: `${this.order?.orderFlightInfos[0]?.companyIataCode}${this.order?.orderFlightInfos[0]?.flightNumber}`,
      nationality: this.translationService.instant(`common.nationality.french`),
      transit: this.translationService.instant(`common.transit.no`),
      tax: this.translationService.instant(
        `common.tarification.${this.order?.orderPricelistApplied}`,
      ),
      taxRegime: this.order?.orderPricelistApplied,
    };

    this.deliveryRef = this.dialogService.open(GeneralDeliveryDialogComponent, {
      contentStyle: { overflow: 'auto' },
      showHeader: false,
      modal: true,
      dismissableMask: true,
      data: {
        clientInfo,
        target: this.order.target,
        orderId: this.order.orderId,
        orderExternalIdentifier: this.order.orderExternalIdentifier,
        orderIsPaid: this.order.orderIsPaid,
      },
    });

    this.deliveryRef.onDestroy.subscribe(() => (this.deliveryRef = undefined));

    this.deliveryRef.onClose.subscribe((action: string) => {
      this.openCancelValidationDialog(action);
    });
  };

  openCancelValidationDialog(action: string): void {
    if (this.cancelValidationRef) {
      return;
    }

    if (action === 'openCancelValidationDialog') {
      this.cancelValidationRef = this.dialogService.open(CancelValidationOrderDialogComponent, {
        contentStyle: { overflow: 'auto' },
        showHeader: false,
        modal: true,
        data: {},
      });

      this.cancelValidationRef.onClose.subscribe((validationAction: string) => {
        if (validationAction === 'cancelOrder') {
          this.removeActionFromUrl();
          this.updateStatus('cancel');
        } else {
          this.openDeliveryDialog();
        }
      });

      this.cancelValidationRef.onDestroy.subscribe(() => (this.cancelValidationRef = undefined));
    }
  }

  preparationIsNotFinished(): boolean {
    return (
      [OrderStatus.IN_PROGRESS, OrderStatus.WAITING_FOR_RESTOCK].includes(this.order.orderStatus) &&
      // user needs to do something with orderlines before finishing preparation
      !!this.order.orderlinesJsonData.find((orderline) => {
        return [
          OrderlineStatus.NEW,
          OrderlineStatus.WAITING_FOR_RESTOCK,
          OrderlineStatus.ERROR,
        ].includes(orderline.statusCode);
      })
    );
  }

  displayPickOLHeader(): boolean {
    return (
      this.viewPick &&
      this.pickMode &&
      this.order.orderlinesJsonData.some((orderline) =>
        [OrderlineStatus.NEW, OrderlineStatus.WAITING_FOR_RESTOCK].includes(orderline.statusCode),
      ) &&
      this.order.orderlinesJsonData.some((orderline) => orderline.stocks?.length > 1)
    );
  }

  initPreparedQty(orderline: Orderline): void {
    if (this.displayPreparedQty()) {
      this.quantitySelection[orderline.orderlineId] = Math.min(
        orderline.productOrderedQty,
        this.stocksSelection[orderline.orderlineId].stock ?? 0,
      );
    }
  }

  displayPreparedQty(): boolean {
    return (
      ![OrderStatus.NEW, OrderStatus.CANCELLED].includes(this.order.orderStatus) &&
      ((this.order.orderType === Target.PAX && this.order.orderChannel === Channel.M2) ||
        (this.order.orderType === Target.VAP && this.order.orderChannel === Channel.M2))
    );
  }

  displayPreparedQtyHeader(): boolean {
    return (
      this.displayPreparedQty() &&
      !(
        !this.pickMode &&
        this.order.orderlinesJsonData.every((orderline) =>
          [OrderlineStatus.NEW, OrderlineStatus.WAITING_FOR_RESTOCK].includes(orderline.statusCode),
        )
      ) &&
      this.order.orderlinesJsonData.some(
        (orderline) =>
          !!(
            orderline.stocks?.length === 1 ||
            (orderline.stocks?.length > 1 &&
              this.stocksSelection[orderline.orderlineId].organizationId)
          ),
      )
    );
  }

  displayPreparedQtyRow(orderline: Orderline): boolean {
    return (
      this.displayPreparedQty() &&
      !(
        !this.pickMode &&
        [OrderlineStatus.NEW, OrderlineStatus.WAITING_FOR_RESTOCK].includes(orderline.statusCode)
      ) &&
      !!(
        orderline.stocks?.length === 1 ||
        (orderline.stocks?.length > 1 && this.stocksSelection[orderline.orderlineId].organizationId)
      )
    );
  }

  displayPreparationNotFinishedDialog(): void {
    this.preparationNotFinishedRef = this.dialogService.open(
      PreparationNotFinishedDialogComponent,
      {
        showHeader: false,
      },
    );
  }

  displayConfirmDeliveryDialog(force = false): void {
    this.confirmDeliveryRef = this.dialogService.open(ConfirmDeliveryDialogComponent, {
      showHeader: false,
      data: { force },
    });

    this.confirmDeliveryRef.onClose.subscribe((confirm: boolean) => {
      if (confirm) {
        this.updateStatus('collect');
      }
    });
  }

  displayOrderUnpaidDialog(): void {
    this.orderUnpaidRef = this.dialogService.open(OrderUnpaidDialogComponent, {
      contentStyle: { overflow: 'auto' },
      showHeader: false,
      modal: true,
      data: {},
    });

    this.orderUnpaidRef.onClose.subscribe((confirm: boolean) => {
      if (confirm) {
        // this.updateStatus('force-collect');
      }
    });

    this.orderUnpaidRef.onClose.subscribe((action: string) => {
      if (action === 'openCancelValidationDialog') {
        this.openCancelValidationDialog(action);
      }
    });
  }

  displayConfirmForceCollectedDialog(): void {
    this.confirmForceCollectedRef = this.dialogService.open(ConfirmForceCollectedDialogComponent, {
      showHeader: false,
    });

    this.confirmForceCollectedRef.onClose.subscribe((confirm: boolean) => {
      if (confirm) {
        this.updateStatus('force-collect');
      }
    });
  }

  displayConfirmForceToCollectDialog(): void {
    this.confirmForceToCollectRef = this.dialogService.open(ConfirmForceToCollectDialogComponent, {
      showHeader: false,
    });

    this.confirmForceToCollectRef.onClose.subscribe((confirm: boolean) => {
      if (confirm) {
        this.updateStatus('force-to-collect');
      }
    });
  }

  addLogMessage() {
    const message = this.logMessageForm.get('logMessage')?.value;
    if (message?.trim().length > 0) {
      this.orderService.addLogMessage(this.order.orderId, message).subscribe((log) => {
        this.order.orderJsonLogs = [...this.order.orderJsonLogs, log];
        this.logMessageForm.get('logMessage')?.reset();
      });
    }
  }

  removeActionFromUrl(): void {
    // remove query params for popin
    this.router.navigate([], {
      queryParams: {
        action: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  updateStatus(endpoint: OrderStatusEndpoint) {
    this.orderService
      .updateStatus(this.order.orderId, endpoint)
      .pipe(switchMap((order) => this.updateStocks(order, true)))
      .subscribe((updatedOrder) => {
        this.order = this.replenishmentService.updateOrder(updatedOrder, updatedOrder.requests);
        this.onStatusUpdate();
      });
  }

  onStatusUpdate() {
    this.viewPick = this.order.orderStatus !== OrderStatus.NEW;
    this.order.state = this.order.state;
    if (this.pickMode && !this.order.state.canPick) {
      // exit pick mode if not allowed
      this.lockPrepare();
    }
    if (
      [
        OrderStatus.PREPARATION_DONE,
        OrderStatus.PREPARATION_DONE_INCOMPLETE,
        OrderStatus.PENDING_READY_TO_CLOSE_STEP1,
        OrderStatus.PENDING_READY_TO_CLOSE_STEP2,
        OrderStatus.PENDING_READY_TO_CLOSE_STEP2_B,
      ].includes(this.order.orderStatus)
    ) {
      this.updateStatusWithInterval();
    } else {
      this.stopUpdateStatusWithInterval();
    }
  }

  updateStatusWithInterval() {
    this.stopUpdateStatusWithInterval();
    timer(PREPARED_ORDER_REFRESH_TIMER * 1000, PREPARED_ORDER_REFRESH_TIMER * 1000)
      .pipe(takeUntil(this.stopUpdateStatusWithIntervalSubject))
      .subscribe(() => this.refresh());
  }

  stopUpdateStatusWithInterval() {
    this.stopUpdateStatusWithIntervalSubject.next(true);
  }

  updateCtaAction(): void {
    if (this.order.cta.details.action === 'start') {
      this.ctaAction = () => this.prepare();
    } else if (this.order.cta.details.action === 'finish') {
      this.ctaAction = () => {
        if (this.preparationIsNotFinished()) {
          this.displayPreparationNotFinishedDialog();
        } else {
          if (this.isExpiryFlightDate()) {
            if (this.dialogFlightDateRef) {
              return;
            }
            this.dialogFlightDateRef = this.dialogService.open(DialogComponent, {
              showHeader: false,
              data: {
                title: 'dialog.cancel-order-flight-date.title',
                content: 'dialog.cancel-order-flight-date.content',
                labelCancelButton: 'dialog.cancel-order-flight-date.cancel',
                labelOkButton: 'dialog.cancel-order-flight-date.confirm',
                onConfirm: () => {
                  this.updateStatus('cancel');
                },
              },
            });
            this.dialogFlightDateRef.onDestroy.subscribe(
              () => (this.dialogFlightDateRef = undefined),
            );
          } else {
            this.updateStatus('finish-preparation');
          }
        }
      };
    } else if (this.order.cta.details.action === 'deliver') {
      this.ctaAction = () => this.openDeliveryDialog();
    } else if (this.order.cta.details.action === 'cancel') {
      this.ctaAction = () => this.displayCancelDialog();
    } else {
      this.ctaAction = () => this.toastMessageService.warn('Action not defined');
    }

    if (this.order.adminCta) {
      this.order.adminCta.forEach((cta, index) => {
        const action = cta.details?.action || 'default';

        const dialogs: { [key: string]: () => void } = {
          'force-deliver': () => this.displayConfirmDeliveryDialog(true),
          'reset-tcpos': () => this.displayResetTcposDialog(),
          'force-cancel': () => this.displayCancelDialog(true),
          'force-collected': () => this.displayConfirmForceCollectedDialog(),
          'force-to-collect': () => this.displayConfirmForceToCollectDialog(),
          cancel: () => this.displayCancelDialog(),
          default: () => function (): void {},
        };

        if (index === 1) {
          this.adminCta1Action = dialogs[action];
        } else {
          this.adminCta0Action = dialogs[action];
        }
      });
    }
  }

  updateStocks(order: Order, fromCache = false): Observable<Order> {
    return forkJoin(
      order.orderlinesJsonData.map((orderline) => {
        return this.productService
          .getStocks(orderline.productSku, order.merchantId, fromCache)
          .pipe(
            tap((stocks) => {
              this.stocksWone[orderline.orderlineId] =
                stocks?.length > 0 ? stocks[0].extendedStock : null;
              orderline.stocks = stocks.sort((a, b) => b.stock - a.stock);
            }),
            tap({
              error: () => {
                orderline.stocks = [];
              },
            }),
            catchError(() => of({})),
          );
      }),
    ).pipe(map(() => order));
  }

  // switch to prepare mode (picking)
  prepare() {
    // Ensure the token has not expired before to start the picking process.
    this.orderService.getById(this.order.orderId);

    this.pickMode = true;
    // replace URL without reloading component
    this.location.replaceState(`/order/${this.order.orderId}/prepare`);
    if (this.order.orderStatus === OrderStatus.NEW) {
      this.updateStatus('start-preparation');
    } else {
      this.updateStatus('continue-preparation');
    }
  }

  resetTcpos() {
    this.pickMode = false;
    // replace URL without reloading component
    if (this.order.orderStatus === OrderStatus.READY_TO_CLOSE) {
      this.displayResetTcposDialog();
    }
  }

  // disable edition
  lockPrepare() {
    this.pickMode = false;
    // replace URL without reloading component
    this.location.replaceState(`/order/${this.order.orderId}`);
  }

  canPreparePartially(): boolean {
    return this.order.orderChannel === Channel.M2;
  }

  pickOL(orderline: Orderline /*, stock: ProductStock*/, organizationId?: string): void {
    if (organizationId) {
      this.stocksSelection[orderline.orderlineId] = {
        organizationId: organizationId,
        organizationCode: this.getOrganizationCode(organizationId, orderline.stocks),
      };
    } else {
      this.stocksSelection[orderline.orderlineId].organizationCode = this.getOrganizationCode(
        this.stocksSelection[orderline.orderlineId].organizationId,
        orderline.stocks,
      );
    }
    this.updateOLStatus(
      orderline,
      this.quantitySelection[orderline.orderlineId] < orderline.productOrderedQty
        ? OrderlineStatus.PARTIAL
        : OrderlineStatus.FULFILLED,
      this.stocksSelection[orderline.orderlineId].organizationId,
      this.quantitySelection[orderline.orderlineId],
    )
      .pipe(switchMap(() => this.refreshOrder(this.order?.orderId, orderline.orderlineId)))
      .subscribe(() => (this.loadingTable = false));
  }

  cancelOL(orderline: Orderline): void {
    if (this.cancelRef) {
      return; // prevent opening the popin multiple times
    }
    this.cancelRef = this.dialogService.open(CancelOrderlineDialogComponent, {
      showHeader: false,
      data: {
        orderline,
      },
    });

    this.cancelRef.onClose.subscribe((action: string) => {
      this.removeActionFromUrl();
      if (action === 'cancel') {
        this.updateOLStatus(orderline, OrderlineStatus.CANCELLED)
          .pipe(switchMap(() => this.refreshOrder()))
          .subscribe();
      }
    });
    this.cancelRef.onDestroy.subscribe(() => (this.cancelRef = undefined));
  }

  // unpick or uncancel
  resetOL(orderline: Orderline): void {
    this.loadingTable = true;
    this.stocksSelection[orderline.orderlineId] = {};
    this.updateOLStatus(orderline, OrderlineStatus.NEW)
      .pipe(switchMap(() => this.refreshOrder(this.order?.orderId, orderline.orderlineId)))
      .subscribe(() => (this.loadingTable = false));
  }

  updateOLStatus(
    orderline: Orderline,
    status: OrderlineStatus,
    pickedFrom?: string,
    quantity?: number,
  ): Observable<Orderline> {
    if (orderline.statusCode === status) {
      return of(orderline);
    }
    return this.orderService
      .updateOrderline(this.order, {
        orderlineId: orderline.orderlineId,
        statusCode: status,
        pickedFrom,
        quantity,
      })
      .pipe(
        tap((updatedOrderline) => {
          Object.assign(orderline, updatedOrderline);
        }),
      );
  }

  getVariations(productInfo: ProductInfo): ProductVariation[] {
    if (!productInfo?.variation) {
      return [];
    }
    return Object.values(productInfo.variation);
  }

  replenish(orderline: Orderline): void {
    this.replenishmentService
      .replenish(orderline, this.order)
      .pipe(
        tap((rr) => (this.order = this.replenishmentService.updateOrder(this.order, [rr]))),
        switchMap(() => this.refreshOrder()),
      )
      .subscribe();
  }

  back() {
    this.historyService.goBackToOrdersList();
  }

  generateBarcode(): void {
    if (this.order.orderTCPOSBarcode && !this.barcordeGenerated) {
      this.viewInitObs.pipe(take(1), delay(0)).subscribe(() => {
        JsBarcode(this.barcode.nativeElement, this.order.orderTCPOSBarcode);
      });
      this.barcordeGenerated = true;
    }
  }

  getOrganizationCode(id = '', stocks: ProductStock[] = []): string {
    return stocks.find((stock) => stock.organizationId === id)?.organizationCode || '';
  }

  isExpiryFlightDate() {
    if (this.order.target === Target.PAX && this.order.orderDeadlinePreparation) {
      // current time + 24 hours
      const currentTime = new Date().getTime();
      const deadlineTime =
        new Date(this.order.orderDeadlinePreparation).getTime() + 24 * 60 * 60 * 1000;
      if (deadlineTime < currentTime) {
        return true;
      }
    }

    return false;
  }

  private updateStocksForOrderLine(order: Order) {
    if (!this.order) {
      return of(order);
    }
    order.orderlinesJsonData.forEach((line) => {
      const lineItem = this.order.orderlinesJsonData.find(
        (l) => l.orderlineId === line.orderlineId,
      );
      if (lineItem) {
        line.stocks = lineItem.stocks;
      }
    });

    return of(order);
  }

  quantityToArray(quantity: number, orderlineId: string): number[] {
    let stockValue = 0;
    let qty;
    if (this.stocksSelection[orderlineId]?.organizationId) {
      const merchantStock = this.getMerchantStocksByOrderline(
        orderlineId,
        this.stocksSelection[orderlineId]?.organizationId ?? '',
      );
      stockValue = merchantStock?.[0].stock ?? 0;
    }
    if (stockValue === 0) {
      qty = this.stocksSelection[orderlineId]?.stock
        ? Math.min(quantity, this.stocksSelection[orderlineId].stock ?? 0)
        : quantity;
    } else {
      qty = stockValue ? Math.min(quantity, stockValue) : quantity;
    }
    return Array.from({ length: qty }, (_, i) => i + 1);
  }

  canDeliver() {
    return this.order.orderIsPaid === true || this.order.orderIsPaid === null;
  }
}
