import { Component, Input, OnInit } from '@angular/core';
import { Channel } from 'src/app/constants/channels';
import { PaymentMethod } from 'src/app/constants/payment-method';
import { Target } from 'src/app/constants/target';
import { BoardingPass, Order } from 'src/app/models';

@Component({
  selector: 'app-order-description',
  templateUrl: './order-description.component.html',
  styleUrls: ['./order-description.component.scss'],
})
export class OrderDescriptionComponent implements OnInit {
  @Input() order: Order;
  @Input() boardingPass: BoardingPass;
  Target = Target;
  Channel = Channel;
  PaymentMethod = PaymentMethod;
  data: Array<string> = [
    'title',
    'phone',
    'company-name',
    'passenger',
    'flight-date',
    'destination-airport',
    'origin-airport',
    'flight-number',
    'nationality',
    'transit',
    'tax',
  ];
  comparableData: Array<string> = [
    'passenger',
    'flight-date',
    'destination-airport',
    'origin-airport',
    'flight-number',
  ];

  ngOnInit() {
    if (this.order?.target === Target.VAP) {
      this.data = ['title', 'phone', 'company-name', 'name'];
    }
    if (this.order?.orderIsPaid !== null) {
      this.data.push('order-is-paid');
    }
  }

  isError(row: string): string {
    return this.order.bypassBoardingPass && this.isComparable(row) ? 'error' : '';
  }

  isComparable(row: string): boolean {
    return this.comparableData.includes(row);
  }
}
